import React from "react"
import { graphql } from "gatsby"
import LatestNews from "./latest-news"

export default function LatestNewsProject(props) {
  return LatestNews(props)
}

export const pageQuery = graphql`
  query WordPressPostArchiveProject(
    $offset: Int!
    $postsPerPage: Int!
    $databaseId: Int!
  ) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: { projectCategoryId: { in: [$databaseId] } }
    ) {
      nodes {
        id
        title
        slug
        content
        excerpt
        date(formatString: "MMMM DD, YYYY")
        project_category {
          project {
            ... on WpProject {
              id
              title
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
